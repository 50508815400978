import React from "react";
import "../components/layout";
import Layout from "../components/layout";
import SEO from "../components/SEO";
import "../../index.css";
import { graphql } from "gatsby";

export default ({ data }) => {
  const entry = data.allMarkdownRemark.edges[0].node;
  const { title, date } = entry.frontmatter;


  return (
    <Layout>
      <SEO frontmatter={data.allMarkdownRemark.edges[0].node} isBlogPost />
      <div className="flex flex-col mt-5 mb-4">
        <div className="">
          <div>
            <p className="text-sm text-gray-500 ">{date}</p>
            <h1 className="mt-16 mb-12 text-2xl" style={{ fontWeight: 600 }}>
              {title}
            </h1>
          </div>
          <div className="mb-16 overflow-hidden remark">
            <div dangerouslySetInnerHTML={{ __html: entry.html }} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    allMarkdownRemark(filter: { fields: { slug: { eq: $slug } } }) {
      totalCount
      edges {
        node {
          id
          timeToRead
          excerpt
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            category
            description
          }
          html
          rawMarkdownBody
          fields {
            slug
          }
        }
      }
    }
  }
`;
